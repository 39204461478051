<template>
  <div>
    <div v-if="paymentOffice" class="rounded-dotted">
      <EStoreCropper
        :movable="false"
        :resizable="false"
        drag-mode="move"
        @cropped="emit('update:modelValue', $event.image)"
      >
        <template #button="picker">
          <div
            class="flex items-center px-1 mt-1 cursor-pointer e_store-text-field"
            @click="picker.pickImage"
          >
            <div
              class="flex-auto font-light text-secondary ltr:text-left rtl:text-right"
            >
              {{ t('payment_image') }}
            </div>

            <div class="w-5"></div>
            <v-icon icon="mdi mdi-attachment" :size="20" />
          </div>
        </template>
      </EStoreCropper>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['update:modelValue'])
const props = defineProps<{
  modelValue?: any
  paymentOffice?: string
}>()
const { t } = useI18n()
watch(
  () => props.paymentOffice,
  (value) => {
    if (!value) {
      emit('update:modelValue', undefined)
    }
  }
)
</script>
