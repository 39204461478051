<template>
  <div v-if="store?.price_visible" class="mb-1">
    <div class="text-lg text-center font-semibold md:hidden mt-2 mb-1">
      {{ t('bill') }}
    </div>
    <div class="rounded-dotted">
      <div class="e_store-details-line">
        <div class="text-lg">{{ t('amount') }}</div>
        <div>
          {{ useStringPrice(countCostItems.originalPrice, currencyCode) }}
        </div>
      </div>

      <div class="e_store-details-line">
        <div class="text-lg">{{ t('discount') }}</div>
        <div>{{ useStringPrice(countCostItems.discount, currencyCode) }}</div>
      </div>

      <div v-if="shippingWay?.type != 'store_pickup' && !tableNumber">
        <div class="e_store-details-line">
          <div class="text-lg">{{ t('shipping_cost') }}</div>
          <div>
            {{ costShippingTitleCart }}
          </div>
        </div>

        <div v-if="shippingWay?.deliveryTime" class="e_store-details-line">
          <div class="text-lg">{{ t('shipping_time') }}</div>
          <div>
            {{
              shippingWay?.deliveryTime
                ? timeToString(shippingWay.deliveryTime)
                : t('unspecified')
            }}
          </div>
        </div>
      </div>
      <div v-if="tablePriceAdded" class="e_store-details-line">
        <div class="text-lg">{{ t('table_cost') }}</div>
        <div>
          {{ useStringPrice(tablePriceAdded, currencyCode) }}
        </div>
      </div>
      <div
        class="flex justify-between pt-4 font-semibold text-text_color text-opacity-80"
      >
        <div class="text-lg">{{ t('total') }}</div>
        <div class="rounded-dotted dark">
          {{ useStringPrice(totalPrice, currencyCode) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { store } = useDomainState()
const props = defineProps<{
  shippingWay?: ShippingWay
  tablePriceAdded?: number
  totalPrice: number
  currencyCode: string
  tableNumber?: string
  countCostItems: {
    originalPrice: number
    discount: number
  }
}>()
function timeToString(totalMinutes: number): string {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  let hourString: string
  let minuteString: string

  if (hours === 0) {
    hourString = ''
  } else if (hours === 1) {
    hourString = t('one_hour')
  } else if (hours === 2) {
    hourString = t('two_hours')
  } else {
    hourString = t('multiple_hours', { count: hours })
  }

  if (minutes === 0) {
    minuteString = ''
  } else if (minutes === 15) {
    minuteString = t('quarter', {
      hour: hourString.trim() ? '' : t('one_hour')
    })
  } else if (minutes === 20) {
    minuteString = t('third', { hour: hourString.trim() ? '' : t('one_hour') })
  } else if (minutes === 30) {
    minuteString = t('half', { hour: hourString.trim() ? '' : t('one_hour') })
  } else {
    minuteString = t('minutes', { count: minutes })
  }

  if (hours !== 0 && minutes !== 0) {
    return t('time_format', { hourString, minuteString })
  } else {
    return hourString + minuteString
  }
}

const costShippingTitleCart = computed(() => {
  const deliveryCost = props.shippingWay?.deliveryCost ?? -1
  if (deliveryCost > 0) {
    return useStringPrice(deliveryCost, props.currencyCode)
  }
  if (deliveryCost == 0) {
    return t('free')
  }
  return t('unspecified')
})
</script>
