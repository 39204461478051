<template>
  <div class="bg-bg_color p-2">
    <div class="text-text_color text-lg sm:text-xl text-center">
      <div>
        {{
          t('you_have_clicked', {
            button: t('apply_by', { by: t('whatsapp') })
          })
        }}
      </div>
      <div class="mt-2">
        {{
          t('clear_cart_?', {
            store: storeDomain?.name
          })
        }}
      </div>
    </div>
    <div class="flex justify-around mt-6">
      <div class="w-28">
        <EStoreSubmitButton
          :title="t('yes')"
          type="button"
          :loading="false"
          @click="deleteMaterialsFromCart"
        />
      </div>
      <div class="w-28 mx-1">
        <EStoreSubmitButton
          :title="t('no')"
          type="button"
          :loading="false"
          @click="closeDialog"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { store: storeDomain } = useDomainState()
const props = defineProps<{
  closeDialog: any
}>()

const cart = useCartStore()
const { t } = useI18n()

async function deleteMaterialsFromCart() {
  await cart.removeMaterialsStoreCart(storeDomain.value!.id)
  props.closeDialog()
}
</script>
