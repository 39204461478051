<template>
  <div>
    <div class="flex items-center">
      <div class="flex-shrink-0 relative overflow-hidden px-2">
        <EStoreLink
          :to="useRouteMaterial(cartItem.material)"
          :aria-label="t('aria_page_label', { page: cartItem.material.name })"
        >
          <NuxtImg
            provider="cacheIpx"
            preset="modified"
            loading="lazy"
            :src="cartItem.material.image"
            :alt="cartItem.material.name"
            width="112"
            height="112"
            class="rounded-lg object-cover object-center m-0.5"
            :class="isHasOfferGroup ? 'w-20 h-20' : 'w-24 h-24'"
          />
          <div
            v-if="
              cartItem.material.discountRate &&
                cartItem.material.store?.price_visible&&!isHasOfferGroup
            "
            class="bg-nav_color pb-0.5 text-text_alt_color w-full text-center text-xs whitespace-nowrap py-0.5"
          >
            <small>
              {{
                t('discount_percentage', {
                  value: parseInt(cartItem.material.discountRate)
                })
              }}
            </small>
          </div>
        </EStoreLink>
      </div>

      <div class="flex-auto flex-shrink-1 px-2">
        <div
          class="text-e_store-light font-medium"
          :class="{ 'text-lg mx-1': !isHasOfferGroup }"
        >
          {{ cartItem.material.name }}
        </div>
        <div class="flex flex-wrap">
          <div
            v-if="isHasOfferGroup"
            class="flex flex-wrap text-sm text-e_store-light mb-1"
          >
            <div class="mx-1">
              {{ t('count') }}
              <span class="bg-nav text-nav_color">{{ cartItem.quantity }}</span>
            </div>
            <div v-if="cartItem.material.store?.price_visible" class="mx-1">
              {{ t('unit_price') }}
              <span class="bg-nav text-nav_color">{{
                cartItem.material.stringPrice
              }}</span>
            </div>
          </div>
          <div
            v-else-if="
              cartItem.material.store?.price_visible &&
                !isHasOfferGroup &&
                !cartItem.quantityByUnit
            "
            class="flex items-center bg-nav w-max"
          >
            <div class="text-e_store-light text-center mx-1">
              <span v-if="cartItem.material.discountPrice">
                {{ cartItem.material.discountPrice }}
              </span>
              <span v-else>{{ cartItem.material.stringPrice }}</span>
            </div>
            <div
              v-if="cartItem.material.discountPrice"
              class="mx-1 text-e_store-light text-center"
            >
              <small class="line-through">
                {{ cartItem.material.stringPrice }}</small>
            </div>
          </div>
          <CartPropertyLookUpValues
            v-if="cartItem?.allDefinitionsLookupValues?.length"
            :key="`cart-store-CartPropertyLookUpValues-${cartItem?.id}`"
            :all-definitions-lookup-values="
              cartItem?.allDefinitionsLookupValues
            "
          />
        </div>
      </div>

      <slot />
    </div>
    <CartNormalItemAddonsPaid :cart-item="cartItem" />
  </div>
</template>

<script setup lang="ts">
import { useRouteMaterial } from '~/composables/useDomain'
defineProps<{
  cartItem: CartItem
  isHasOfferGroup?: boolean
}>()
const { t } = useI18n()
</script>
