<template>
  <div
    v-if="availableShippingWays.length"
    class="rounded-dotted mt-1 text-text_color text-opacity-80"
  >
    <div class="flex md:justify-around justify-between items-center">
      <div class="md:font-semibold md:text-lg">{{ t('delivery_methods') }}</div>
      <div class="mx-2 mt-1">
        <div
          v-for="(item, index) in availableShippingWays"
          :key="`availableShippingWays-${index}`"
        >
          <div
            class="flex cursor-pointer rounded-dotted light mb-1"
            @click="updateMethodShipping(item)"
          >
            <div class="w-2"></div>
            <input
              v-model="updateShippingWays"
              :disabled="item.disable"
              type="radio"
              :value="item"
            />
            <div class="w-4"></div>
            <div class="mx-1">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <CartCostCartAgentPlaces
      v-if="isAuth"
      :selected-agent-place="modelValue?.agentPlace"
      :agent-places="getPlaces"
      @select-agent-place="updateAgentPlace($event)"
    />
    <div
      v-if="modelValue?.type == 'store_delivering_way'"
      class="text-center bg-bg_color brightness-95 font-semibold text-sm p-1 border-t border-text_alt_color rounded-lg mt-6"
    >
      {{ store?.shipping_details }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'
import type { ShippingWay } from '~/composables/useMenuModel'
const { t } = useI18n()
const { isAuthLoggedIn } = useMenuModel()
const { store } = useDomainState()
const isAuth = isAuthLoggedIn()
const props = defineProps<{
  modelValue?: ShippingWay
  costItemsStore: {
    originalPrice: number
    discount: number
  }
}>()
const { getPlaces } = usePlacesStore()

function deliveryStorePrivateDetails(newAgentPlace?: AgentPlace) {
  const minimumPriceNumber = parseFloat(
    store.value?.minimum_price_for_zero_delivery_cost || '0'
  )
  let deliveryCostLocal = parseFloat(store.value?.delivery_cost || '0')
  const totalPrice =
    props.costItemsStore.originalPrice - props.costItemsStore.discount
  if (totalPrice > minimumPriceNumber) {
    deliveryCostLocal = 0
  }
  return {
    deliveryCost: deliveryCostLocal,
    agentPlace:
      newAgentPlace ||
      props.modelValue?.agentPlace ||
      (getPlaces.length ? getPlaces[0] : undefined),
    disable: false,
    type: 'store_delivering_way',
    deliveryTime: store.value?.delivery_duration || 0,
    title: t('store_delivering_way', {
      name: store.value?.name
    })
  }
}
function updateAgentPlace(agentPlace: AgentPlace) {
  if (agentPlace.id !== props.modelValue?.agentPlace?.id) {
    updateMethodShipping(deliveryStorePrivateDetails(agentPlace))
  }
}
const updateShippingWays = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    updateMethodShipping(value)
  }
})
function updateMethodShipping(value: ShippingWay | undefined) {
  emit('update:modelValue', value)
}

const emit = defineEmits<{
  (event: 'update:modelValue', value?: ShippingWay): void
}>()
const availableShippingWays = computed(() => {
  const availableShippingWaysTemp = ref<ShippingWay[]>([
    deliveryStorePrivateDetails()
  ])

  if (
    store.value?.active_package?.package?.support_whats_app_invoices &&
    !isAuth
  ) {
    availableShippingWaysTemp.value.push({
      deliveryCost: undefined,
      deliveryTime: undefined,
      type: 'store_pickup',
      title: t('store_pickup'),
      agentPlace: undefined
    })
  }

  return availableShippingWaysTemp.value
})

onMounted(() => {
  updateMethodShipping(availableShippingWays.value[0])
})
</script>
