<template>
  <div
    v-if="table?.table_number"
    class="absolute md:-translate-y-1/2 rtl:left-2 ltr:right-2 md:top-1/2 -top-3"
  >
    <div
      class="bg-nav_color text-xs text-text_alt_color p-2 mx-2 h-max flex justify-center items-center rounded-lg"
    >
      <div class="ltr:mr-2 rtl:ml-2">
        {{ t('table_with', { number: table?.table_number }) }}
      </div>
      <v-icon
        icon="fa: fa-solid fa-circle-xmark"
        :size="16"
        class="m-auto"
        @click="clearTableID()"
      />
    </div>
    <EStoreLoadingIndicator
      :size="5"
      color="border-nav_color"
      class="text-nav_color mt-2"
      :loading="loading"
    />
  </div>
</template>

<script setup lang="ts">
import type { Store, Table } from '~/composables/useMenuModel'
const { t } = useI18n()
const props = defineProps<{
  table?: Table
  store: Store
}>()
const { editUserHaveTable } = useDomainState()
const cart = useCartStore()
async function clearTableID() {
  if (!loading.value) {
    loading.value = true

    const dataApi = await setTableToStore(props.store.id.toString(), null)
    if (cart.handleReponseApi(dataApi, props.store.id.toString())) {
      editUserHaveTable(false)
      await cart.serverLoad(true).then(() => {
        loading.value = false
      })
    } else {
      loading.value = false
    }
  }
}
const { setTableToStore } = useCartMethods()
const loading = ref(false)
</script>
