<template>
  <div>
    <div class="my-3">
      <div class="text-lg">{{ t('notes') }}</div>
      <textarea
        v-model="notes"
        rows="3"
        class="w-full rounded bg-bg_color outline-none text-text_color p-1 border focus:border-text_color"
      />
    </div>
    <div class="flex justify-center">
      <EStoreSubmitButton
        v-if="
          store?.active_package?.package?.support_whats_app_invoices ||
            table?.table_id
        "
        :title="t('apply_by', { by: t('whatsapp') })"
        type="button"
        width="w-max md:w-full"
        :loading="false"
        @click="goToWhatsAppMessage()"
      />

      <EStoreSubmitButton
        v-else-if="
          store?.active_package?.package?.support_user_login &&
            store?.active_package?.package?.support_invoices
        "
        :title="t('order_now')"
        type="button"
        width="w-max md:w-full"
        icon="mdi mdi-check"
        :loading="loadingBuyBill"
        @click="orderBillNow"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Store, Table } from '~/composables/useMenuModel'
const { chechIfStoreIsOpennning } = useBill()

const {
  form: billForm,
  buyBill,
  resetForm,
  checkErrorBill,
  loadingBuyBill
} = useBill()

const notes = ref('')

const { t } = useI18n()
const props = defineProps<{
  selectedShippingWay?: ShippingWay
  tableId?: number | string
  itemsWithOutOffers: CartItem[]
  totalPrice: number
  tablePriceAdded: number
  allOffersWithItems: CartItem[][]
  countCostItems: {
    originalPrice: number
    discount: number
  }
  currency: {
    code: any
    currencyIsRtl: any
  }
  itemsWithOffers: object
  paymentImage: any
  selectedPayWays: {
    payType?: string
    payWayCard?: payWay
  }
  table?: Table
}>()
const { store } = useDomainState()
async function orderBillNow() {
  resetForm()
  billForm.bill_details = billDetails.value
  billForm.note = notes.value.trim()
  billForm.table_id = props.table?.table_id
  billForm.payment_image = props.paymentImage
  billForm.payment_office = props.selectedPayWays.payWayCard?.name
  billForm.agent_place_id = props.selectedShippingWay?.agentPlace?.id
  billForm.store_id = store.value?.id

  if (props.selectedPayWays.payType?.trim().length) {
    if (props.selectedPayWays.payWayCard) {
      billForm.payment_type = 0
      const accountOwnerName = (
        props.selectedPayWays?.payWayCard.account_owner_name || ''
      ).trim()
      billForm.payment_details = ` نظام الدفع : ${
        props.selectedPayWays?.payWayCard.name
      } ${
        accountOwnerName.length ? ' - مالك الحساب : ' + accountOwnerName : ''
      } -  رقم التحويل : ${props.selectedPayWays.payWayCard.iban} `
    } else {
      billForm.payment_type = 1
    }
  }
  const isHasNotInitailError =
    checkErrorBill(
      store.value!,
      props.totalPrice,
      props.table,
      props.selectedPayWays,
      props.selectedShippingWay
    ) || false
  if (!isHasNotInitailError) {
    await buyBill()
  }
}
function openDialog(store?: Store) {
  emit('open-dialog', { store })
}
const emit = defineEmits(['open-dialog'])
const billDetails = computed(() => {
  const itemsWithOutOffers = [] as any
  const itemsWithOffers = [] as any

  props.allOffersWithItems.forEach((itemsPerOffer: CartItem[]) => {
    itemsPerOffer.forEach((item: CartItem) => {
      itemsWithOffers.push({
        quantity: item.quantity,
        material: { id: item.material.id, price: item.material.price },
        material_group_offer_id: item.material_group_offer_id,
        group_offer_uuid: item.group_offer_uuid,
        addons: item.addons?.length ? reduceAddons(item) : undefined,
        material_id: item.material.id
      })
    })
  })
  props.itemsWithOutOffers.forEach((item: CartItem) => {
    itemsWithOutOffers.push({
      quantity: item.quantity,
      material: { id: item.material.id, price: item.material.price },
      material_id: item.material.id,
      propertiesLookupValues: item.properties_lookup_values?.length
        ? reducePropertiesLookup(item)
        : undefined,
      addons: item.addons?.length ? reduceAddons(item) : undefined
    })
  })
  const allItems = [...itemsWithOutOffers, ...itemsWithOffers]
  return JSON.stringify(allItems)
})
function reducePropertiesLookup(item: CartItem) {
  const props = item.properties_lookup_values?.reduce((r: any, a) => {
    return [
      ...r,
      {
        property_definition_id: a.property_id || a.property_definition_id,
        value_id: a.value_id
      }
    ]
  }, [])

  return props?.length ? props : []
}
function reduceAddons(item: CartItem) {
  const props = item.addons?.reduce((r: any, a) => {
    return [
      ...r,
      {
        id: a.id,
        quantity: a.pivot?.quantity
      }
    ]
  }, [])

  return props?.length ? props : []
}
function goToWhatsAppMessage() {
  const isStoreOpening = store.value
    ? chechIfStoreIsOpennning(store.value)
    : true
  if (isStoreOpening) {
    useSentItemsCartByWhatsApp(
      props.itemsWithOutOffers,
      props.itemsWithOffers,
      store.value!,
      props.totalPrice,
      notes.value,
      props.countCostItems,
      props.selectedPayWays,
      props.selectedShippingWay,
      props.tablePriceAdded,
      props.table,
      props.currency,
      openDialog
    )
  }
}
</script>
