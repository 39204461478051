<template>
  <div v-if="badgeCount">
    <div v-if="offers">
      <RikazModalDialog overlay="bg-black opacity-95">
        <template #trigger="{ open }">
          <div >
            <CartItemsWithCost
              :offers="offers"
              :store-with-items="itemsStore"
              @open-dialog=";[(dataDialog = $event), open()]"
            />
          </div>
        </template>

        <template #content="{ close }">
          <div class="text-white">
            <HomeOffersGroupSlide
              v-if="dataDialog?.offer"
              class="mx-auto"
              style="width: 80vw"
              :offer="dataDialog?.offer"
            />
            <CartDeleteAllMaterials v-else :close-dialog="close" />
          </div>
        </template>
      </RikazModalDialog>
    </div>
  </div>
  <div v-else class="flex flex-col items-center justify-center mt-20">
    <IconsEmptyCart class="w-20 h-20 sm:w-28 sm:h-28" />

    <p class="mt-10 mb-5">{{ t('empty_cart') }}</p>

    <EStoreLink
      to="/"
      class="bg-button_color text-text_alt_color rounded py-1 px-2"
      :aria-label="t('aria_page_label', { page: t('home') })"
    >
      {{ t('browse', { page: t('materials') }) }}
    </EStoreLink>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { GroupOffer } from '~~/composables/useMenuModel'

const offers = ref<GroupOffer[] | null>(null)
const cart = useCartStore()
const dataDialog = ref<any>(null)
const itemsStore: ItemStoreCart = computed(() => {
  return cart.itemsByStoreSortedByOffer
})
const badgeCount = computed(() => cart.badgeCount)
const { t } = useI18n()

async function fetchOffers() {
  const materialIds = itemsStore.value?.items?.withOutOffers.reduce(
    (total: number[], cartItem: CartItem) => {
      total.push(cartItem.material_id)
      return total
    },
    []
  )

  if (materialIds.length) {
    await useBasicFetch(
      getAllRoutes().materialsRoutes.materialGroupOfferByServer,
      {
        query: { 'material_ids[]': materialIds },
        transform: (data: SuccessResponse<GroupOffer[]>) => {
          const { groupOfferModel } = useMenuModel()
          data.data.map(groupOfferModel)
          offers.value = data.data
        }
      }
    )
  } else {
    offers.value = []
  }
}

await fetchOffers()
</script>
