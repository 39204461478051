<template>
  <div v-if="errors?.length" class="border border-red-500 bg-red-50 rounded my-1 p-2">
    <ul class="list-disc px-5 text-red-500 text-xs break-words">
      <li v-for="(err, j) in errors" :key="`server-response-err-${j}`">
        {{ err }}
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
const errors = computed(() => (useCartStore().errors || {}))

</script>
